export const environment = {
    production: true,
    apiBaseUrl: 'https://api.logibids.com/v1/',
    socketUrl: 'https://api.logibids.com/',
    defaultLang: 'en',
    GOOGLE_API_KEY: 'AIzaSyBeJCUhMKMXbYzmmVgbg2TjZ3pke3yFBBE',
    baseUrl: 'https://api.logibids.com/v1/',
    pusher: {
        key: '8a95c1ef06e601684784',
        cluster: "eu",
        authEndpoint: 'https://logibidsapi.appgrowthcompany.com/v1/pusher-authenticate'
    }
};

/**
 * https://devapi.logibids.com/
 * https://stagingapi.logibids.com/
 * https://api.logibids.com/
 */
